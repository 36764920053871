<template>
  <v-app>
    <div class="container">
      <router-view />
  </div>
  </v-app>
</template>

<script setup>
</script>

<style>
.v-application__wrap {
  min-height: 0vh !important;
}
</style>

