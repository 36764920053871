/**
 * main.js
 *
 * Bootstraps Vuetify and other plugins then mounts the App`
 */

// Plugins
import { registerPlugins } from '@/plugins'
import * as iframeResize from 'iframe-resizer/js/iframeResizer.contentWindow';



// Components
import App from './App.vue'

// Styles
import '@/assets/styles/web.scss'

// Composables
import { createApp } from 'vue'

const app = createApp(App)

app.directive('resize', {
  bind: function (el, { value = {} }) {
    el.addEventListener('load', () => iframeResize(value, el))
  },
  unbind: function (el) {
    el.iFrameResizer.removeListeners();
  }
})

registerPlugins(app)

app.mount('#app')


